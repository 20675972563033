<template>
  <div class="sug">
    <title-top>意见反馈</title-top>

    <div class="row align-center ml25 h98">
      <img class="imgsize32" src="~images/write.png" alt />
      <span class="f-333333 font28 ml16">填写意见</span>
    </div>
    <div class="row just-center">
      <textarea class="br5 font24 f-999999 area" v-model="content" placeholder="留下您宝贵的意见，我们会尽快帮您处理！" maxlength="-1"></textarea>
    </div>
    <div class="row align-center ml25 h98">
      <img class="imgsize32" src="~images/upload.png" alt />
      <span class="f-333333 font28 ml16">上传图片</span>
    </div>
    <div class="row align-center">
      <div class="row align-center addbg br5">
        <img class="addimg ml30" :src="item" v-for="(item,index) in url" :key="index" alt v-if="url.length>0" />
        <div class="addimg ml30 position_r" v-if="url.length<3">
          <img class="addimg" src="~images/add.png" alt />
          <input
            type="file"
            name
            class="file"
            accept="image/gif, image/jpeg, image/jpg, image/png"
            @change="fileChange"
          />
        </div>
        
      </div>
    </div>
    <div class="row flex-center" @click="onSure">
      <div class="btn-red mt170">提交意见</div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import TitleTop from "@/components/common/title/TitleTop";
import { upload_img,feed_backs } from "network/igou";

export default {
  name: "Sug",
  data() {
    return {
      content:'',
      images: [],
      url:[]
    };
  },
  methods: {
    onSure(e) {
      if(!this.content){
        this.$toast.show('请填写内容');
        return;
      }
      feed_backs(this.content,this.images).then(res=>{
        this.$toast.show('提交成功');
        this.$router.back();
      })
    },
    //头像选择
    fileChange(e) {
      var that = this;
      var file = e.target.files[0];//本地路径
      var reader = new FileReader();
      reader.onload = function(e) {
        that.images.push(file);
        console.log(that.images)
        that.url.push(e.target.result);//base64格式
        
      };
      reader.readAsDataURL(file);
    },
  },
  components: {
    TitleTop
  }
};
</script>
<style  scoped>
.file {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
}
.area {
  border: 0.026667rem solid #cccccc;
  outline: none;
  background-color: #fcfcfc;
  padding: 0.106667rem;
  line-height: 0.466667rem;
  width: 8.866667rem;
  height: 3.706667rem;
}
.addbg {
  width: 9.04rem;
  height: 3.173333rem;
  background: rgba(252, 252, 252, 1);
  border: 0.026667rem solid rgba(204, 204, 204, 1);
}
.addimg {
  width: 2.4rem;
  height: 2.4rem;
  border: 0.013333rem solid #cccccc;
}
</style>
